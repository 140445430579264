exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-future-collaboration-tsx": () => import("./../../../src/pages/future/collaboration.tsx" /* webpackChunkName: "component---src-pages-future-collaboration-tsx" */),
  "component---src-pages-future-participation-tsx": () => import("./../../../src/pages/future/participation.tsx" /* webpackChunkName: "component---src-pages-future-participation-tsx" */),
  "component---src-pages-future-services-tsx": () => import("./../../../src/pages/future/services.tsx" /* webpackChunkName: "component---src-pages-future-services-tsx" */),
  "component---src-pages-future-tsx": () => import("./../../../src/pages/future.tsx" /* webpackChunkName: "component---src-pages-future-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-past-formation-tsx": () => import("./../../../src/pages/past/formation.tsx" /* webpackChunkName: "component---src-pages-past-formation-tsx" */),
  "component---src-pages-past-references-tsx": () => import("./../../../src/pages/past/references.tsx" /* webpackChunkName: "component---src-pages-past-references-tsx" */),
  "component---src-pages-past-tsx": () => import("./../../../src/pages/past.tsx" /* webpackChunkName: "component---src-pages-past-tsx" */),
  "component---src-pages-present-strengths-tsx": () => import("./../../../src/pages/present/strengths.tsx" /* webpackChunkName: "component---src-pages-present-strengths-tsx" */),
  "component---src-pages-present-team-tsx": () => import("./../../../src/pages/present/team.tsx" /* webpackChunkName: "component---src-pages-present-team-tsx" */),
  "component---src-pages-present-tsx": () => import("./../../../src/pages/present.tsx" /* webpackChunkName: "component---src-pages-present-tsx" */),
  "component---src-pages-present-values-tsx": () => import("./../../../src/pages/present/values.tsx" /* webpackChunkName: "component---src-pages-present-values-tsx" */),
  "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-data-protection-de-mdx": () => import("./../../../src/templates/GenericPage.tsx?__contentFilePath=/home/runner/work/openscript.ch/openscript.ch/content/data/pages/data-protection.de.mdx" /* webpackChunkName: "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-data-protection-de-mdx" */),
  "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-data-protection-en-mdx": () => import("./../../../src/templates/GenericPage.tsx?__contentFilePath=/home/runner/work/openscript.ch/openscript.ch/content/data/pages/data-protection.en.mdx" /* webpackChunkName: "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-data-protection-en-mdx" */),
  "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-imprint-de-mdx": () => import("./../../../src/templates/GenericPage.tsx?__contentFilePath=/home/runner/work/openscript.ch/openscript.ch/content/data/pages/imprint.de.mdx" /* webpackChunkName: "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-imprint-de-mdx" */),
  "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-imprint-en-mdx": () => import("./../../../src/templates/GenericPage.tsx?__contentFilePath=/home/runner/work/openscript.ch/openscript.ch/content/data/pages/imprint.en.mdx" /* webpackChunkName: "component---src-templates-generic-page-tsx-content-file-path-content-data-pages-imprint-en-mdx" */)
}

